var checkResize;
$(window).on('load resize', function() {
  clearTimeout(checkResize);
  checkResize = setTimeout(resizing, 100);
});

function resizing() {
  var w = $(window).width();
  var windowWidth = $(window).width();
  var gnav = $('.gNav');
  if (windowWidth > 768) {
    gnav.attr('aria-hidden', 'false');
  } else {
    gnav.attr('aria-hidden', 'true');
  }
}



$(function() {
  // PageScrolling
  var scrollElm = (function() {
    if ('scrollingElement' in document) {
      return document.scrollingElement;
    }
    if (navigator.userAgent.indexOf('WebKit') != -1) {
      return document.body;
    }
    return document.documentElement;
  })();

  $('a[href^="#"]').not('.searchTab__item a').on('click', function() {
    var speed = 300;
    var easing = 'swing';
    var href = $(this).attr("href");
    $(scrollElm).animate({
      scrollTop: $(href == "#" ? 'html' : href).offset().top
    }, speed, easing);
    return false;
  });

  // Drawer
  $('.drawer').drawer({

    nav: 'drawer-nav',
    toggle: 'drawer-toggle',
    overlay: 'drawer-overlay',
    open: 'drawer-open',
    close: 'drawer-close',
    dropdown: 'drawer-dropdown'
  });
  $('.drawer-toggle').on('click', function() {
    $('.gNav').attr("aria-hidden", $('.gNav').attr('aria-hidden') == 'false' ? 'true' : 'false');
  });
});
